import React from "react"

const Footer = () => {
  const footerItems = [
    { name: "Github", link: "https://github.com/thanapatsan" },
    { name: "Twitter", link: "https://twitter.com/thanapatsan" },
  ]
  return (
    <footer className="footer mt-4 border-t">
      <div className="container px-4 py-2 max-w-screen-xl sm:flex sm:items-center sm:justify-between">
        <div className="flex items-center justify-center">
          <small>
            &copy; 2017-{new Date().getFullYear()} Thanapat Sanongyat
          </small>
        </div>
        <nav
          className="my-0 sm:my-0 sm:flex items-center md:inline-block"
          id="menu"
        >
          <ul className="flex items-center justify-center mx-0 sm:mx-0 my-1 sm:my-0 px-0 sm:px-0 py-0 sm:py-0">
            {footerItems.map(item => (
              <li key={item.name} className="inline-block">
                <small>
                  <a href={item.link} className="menu--item px-2 py-1 sm:py-1">
                    {item.name}
                  </a>
                </small>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </footer>
  )
}

export default Footer
