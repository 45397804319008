import * as React from "react"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ location, children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header location={location} />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
